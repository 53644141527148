import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import '../../assets/scss/init.scss';
import { Navbar } from '../Navbar';

type IProps = {
  children: any;
};

export const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <Container className="container">
        <Helmet defaultTitle="Blog by John Doe" />
        {children}
      </Container>
    </>
  );
};

const Container = styled.section`
  padding: 3rem 1.5rem;
`;

export default Layout;

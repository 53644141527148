import styled from 'styled-components';

export const Card = styled.div`
  font-size: 16px;
  background: white;
  border-radius: 12px;
`;

export const CardContent = styled.div`
  padding: 1.5rem;
`;
